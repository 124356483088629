import { DESKTOP_SMALL_BREAKPOINT } from '@maverick/utils';
import styled, { css } from 'styled-components';

export const Container = styled.div`
	color: #e5c8a2;
	font-size: 16px;
	font-weight: 700;
	font-family: 'Roboto-Condensed-Bold', sans-serif;
	margin-right: 32px;

	a {
		text-decoration: underline;

		&:hover {
			opacity: 0.8;
		}
	}

	@media (max-width: ${DESKTOP_SMALL_BREAKPOINT}px) {
		font-size: 12px;
		line-height: 14px;
		position: absolute;
		top: 100%;
		background-color: #f2e4d1;
		color: #2c2c2b;
		left: 0px;
		right: 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 32px;
		padding: 8px;
		margin-right: unset;
	}
`;

export const LocationContainer = styled.a`
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 8px;
	align-items: center;
	justify-content: center;
	text-decoration: unset !important;

	&:hover {
		opacity: 0.7;
		cursor: pointer;
	}
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	width: fit-content;

	@media (max-width: ${DESKTOP_SMALL_BREAKPOINT}px) {
		width: 100%;
	}
`;

export const Name = styled.div<{ hasLocation: boolean }>`
	font-weight: bold;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	text-decoration: underline;
	${({ hasLocation }) =>
		hasLocation &&
		css`
			text-transform: uppercase;
		`};

	@media (min-width: ${DESKTOP_SMALL_BREAKPOINT}px) {
		max-width: 150px;
	}
`;

export const LocationIcon = styled.div`
	width: 17px;
	height: 20px;

	@media (max-width: ${DESKTOP_SMALL_BREAKPOINT}px) {
		width: 17px;
		height: 17px;
	}
`;
