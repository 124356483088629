import { useEffect } from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import Layout from 'src/Layout';
import { SitecoreContext, ComponentPropsContext, handleEditorFastRefresh } from '@sitecore-jss/sitecore-jss-nextjs';
import { SitecorePageProps } from 'lib/page-props';
import { sitecorePagePropsFactory } from 'lib/page-props-factory';
import { componentFactory } from 'temp/componentFactory';
import { sitemapFetcher } from 'lib/sitemap-fetcher';
import { ThemeProvider } from 'styled-components';
import { NotFound } from 'components/cms/NotFound';
import { OutbackTheme } from '@maverick/themes';
import { DictionaryContext, FeatureFlag, IDictionary } from '@maverick/cms';
import { NavigationProps } from '@maverick/entity';
import { Config } from 'src/Config';
import { GetRedirects } from 'src/services/SitecoreRoutes.service';
import { GetNativeLoginFlag, GetOnlySignupSDK } from 'src/services/App.service';

interface StaticProps {
	nativeLoginFlag: boolean;
	onlySignUpSDK: boolean;
}

const SitecorePage = (props: SitecorePageProps & StaticProps): JSX.Element => {
	const { layoutData, componentProps, notFound, dictionary, nativeLoginFlag, onlySignUpSDK } = props;

	const dictionaryMapped = IDictionary.Make(dictionary);
	const navigationItems: NavigationProps = {
		mainItems: [],
		hamburgerItems: [],
		featuredItems: [],
		unfeaturedItems: [],
	};

	useEffect(() => {
		// Since Sitecore editors do not support Fast Refresh, need to refresh EE chromes after Fast Refresh finished
		handleEditorFastRefresh();
	}, []);

	if (notFound || !layoutData?.sitecore?.route) {
		// Shouldn't hit this (as long as 'notFound' is being returned below), but just to be safe
		return (
			<NotFound
				items={navigationItems}
				homeData={null}
				dictionary={dictionaryMapped}
				auth0Config={Config.Auth0}
			/>
		);
	}

	return (
		<>
			<DictionaryContext.Provider value={{ dictionary: dictionaryMapped }}>
				<ThemeProvider theme={OutbackTheme}>
					<FeatureFlag nativeLoginFlag={nativeLoginFlag} onlySignUpSDK={onlySignUpSDK} />
					<ComponentPropsContext value={componentProps}>
						<SitecoreContext componentFactory={componentFactory} layoutData={layoutData}>
							<Layout layoutData={layoutData} />
						</SitecoreContext>
					</ComponentPropsContext>
				</ThemeProvider>
			</DictionaryContext.Provider>
		</>
	);
};

// This function gets called at build and export time to determine
// pages for SSG ("paths", as tokenized array).
export const getStaticPaths: GetStaticPaths = async (context) => {
	// Fallback, along with revalidate in getStaticProps (below),
	// enables Incremental Static Regeneration. This allows us to
	// leave certain (or all) paths empty if desired and static pages
	// will be generated on request (development mode in this example).
	// Alternatively, the entire sitemap could be pre-rendered
	// ahead of time (non-development mode in this example).
	// See https://nextjs.org/docs/basic-features/data-fetching#incremental-static-regeneration

	try {
		if (process.env.NODE_ENV !== 'development') {
			// Note: Next.js runs export in production mode
			const paths = (await sitemapFetcher.fetch(context)).filter((p) => p.params.path[0] !== 'menu');

			return {
				paths,
				fallback: process.env.EXPORT_MODE ? false : 'blocking',
			};
		}
	} catch (ex) {
		console.warn('Error getting paths from context', ex);
	}

	return {
		paths: [],
		fallback: 'blocking',
	};
};

// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation (or fallback) is enabled and a new request comes in.
export const getStaticProps: GetStaticProps = async (context) => {
	const props = await sitecorePagePropsFactory.create(context);

	const dataSource = `/sitecore/content/BBI/${Config.Brand}/Settings/Redirects`;
	const redirects = await GetRedirects(dataSource, context.locale || 'en');

	const path = context.params?.path;
	const currentPath = path ? `/${Array.isArray(path) ? path.join('/') : path}` : '/';

	const redirect = redirects.find((r) => r.sourceUrl === currentPath);

	if (redirect && redirect.targetUrl) {
		return {
			redirect: {
				destination: redirect.targetUrl,
				permanent: redirect.type === 'Redirect301',
			},
		};
	}

	const nativeLoginFlag = await GetNativeLoginFlag();
	const onlySignUpSDK = await GetOnlySignupSDK();

	return {
		props: {
			...props,
			redirects,
			nativeLoginFlag,
			onlySignUpSDK
		},
		// Next.js will attempt to re-generate the page:
		// - When a request comes in
		// - At most once every 5 seconds
		revalidate: Config.RevalidationPeriod, // In seconds
		notFound: props.notFound, // Returns custom 404 page with a status code of 404 when true
	};
};

export default SitecorePage;
