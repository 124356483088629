import { LocationPopUp } from './LocationPopUp';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '@maverick/store';

const mapStateToProps = (state: RootState) => ({
	seatingData: state.seating.seatingData,
	restaurant: state.location.restaurant,
});

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type LocationPopUpReduxProps = ConnectedProps<typeof connector>;
const LocationPopUpConnected = connector(LocationPopUp);
export { LocationPopUpConnected as LocationPopUp };
